import { MenuIcon, XIcon, SearchIcon } from "@heroicons/react/outline";
import { Disclosure, Menu } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";

// Custom imports:
import { signOutHandler } from "@/lib/auth/signOut";
import { classNames } from "@/lib/helpers";
import { useNotifications } from "@/lib/provider-notifications";
import { useAuth } from "@/lib/provider-authentication";
import { useLogin } from "@/lib/login";
import { useRouter } from "next/router";
import { useState } from "react";

// Site search modal imports:
import { useSearchModal } from "@/lib/provider-useSearchModal";

export default function WebsiteNavigation({ navigation }) {
  const { user } = useAuth();
  const { setModalOpen } = useLogin();
  const { addNotification } = useNotifications();
  const { isSearchOpen, setIsSearchOpen } = useSearchModal();
  const [isHoliday, setIsHoliday] = useState(false);

  // Check to see which navigation is the current one, if any:
  const router = useRouter();
  const currentNavigation = navigation.map((navigationLink) => {
    if (navigationLink.href === router.asPath) {
      navigationLink.current = true;
      return navigationLink;
    } else {
      navigationLink.current = false;
      return navigationLink;
    }
  });

  useEffect(() => {
    const isHoliday = async () => {
      const response = await fetch("/api/check-holiday");
      const data = await response.json();
      setIsHoliday(data.isHoliday);
    };

    isHoliday();
  }, [user]);

  return (
    <header className="sticky top-0 z-20">
      {isHoliday && (
        <div className="bg-tertiary text-white hover:underline">
          <a href="/pricing/">
            <p className="mx-auto py-2 text-center text-xl">
              <span className="font-bold">Black Friday Sale</span>
              <span>: 30% off for life. </span>➝
            </p>
          </a>
        </div>
      )}
      <Disclosure as="nav" className="bg-background-dark">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="focus:outline-none inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link href="/courses/">
                      <a href="/courses/">
                        <Image
                          priority={true}
                          className="hidden h-8 w-auto lg:block"
                          src="/images/vexpower-logo.svg"
                          alt="Company Logo - Vexpower"
                          height="30"
                          width="120"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {currentNavigation.map((item) => {
                        // TODO - Disabled timeline from here for now:
                        if (item.href === "/favourites/" && !user) return null;
                        return (
                          <Link href={item.href} key={item.href}>
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-background-dark text-white"
                                  : "text-gray-light hover:text-white",
                                "rounded-md px-3 py-2 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </a>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile Picture */}
                  {user?.uid && user?.photoURL ? (
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="focus:outline-none flex rounded-full bg-background-dark text-sm focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-dark">
                          <span className="sr-only">Open user menu</span>
                          <Image
                            priority={true}
                            className="h-8 w-8 rounded-full"
                            src={user.photoURL}
                            alt="User profile picture"
                            height="30"
                            width="30"
                          />
                        </Menu.Button>
                      </div>
                      <Menu.Items className="focus:outline-none absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                        <Menu.Item>
                          {({ active }) => (
                            <Link href="/profile/">
                              <a
                                className={classNames(
                                  active ? "bg-gray-mid" : "",
                                  "block px-4 py-2 text-sm text-black"
                                )}
                              >
                                Profile
                              </a>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/plans/"
                              className={classNames(
                                active ? "bg-gray-mid" : "",
                                "block px-4 py-2 text-sm text-black"
                              )}
                            >
                              Plan
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={async () => {
                                const loggedOutEmail = user?.email;
                                const uid = user?.uid;
                                signOutHandler(user, uid);
                                addNotification({
                                  style: "success",
                                  title: "You're logged out now",
                                  body: `User ${loggedOutEmail} just logged out.`,
                                });
                              }}
                              className={classNames(
                                active ? "bg-background-light" : "",
                                "block px-4 py-2 text-sm text-gray-dark"
                              )}
                            >
                              Log Out
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  ) : !user?.uid ? (
                    <button
                      type="button"
                      className="focus:outline-none hidden items-center border border-transparent bg-gray-light px-4 py-2 text-sm font-medium text-black shadow hover:bg-secondary hover:text-white focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:inline-flex"
                      onClick={(e) => {
                        e.preventDefault();
                        setModalOpen(true);
                      }}
                    >
                      Access
                    </button>
                  ) : (
                    <></>
                  )}
                  <div className="ml-6">
                    <SearchIcon
                      onClick={(e) => {
                        e.preventDefault();
                        setIsSearchOpen(!isSearchOpen);
                      }}
                      className="h-7 w-7 cursor-pointer text-white"
                    />
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel
              className="sm:hidden"
              style={{
                position: "absolute",
                top: "100%",
                right: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              <div className="space-y-1 bg-background-dark px-2 pt-2 pb-3">
                {currentNavigation.map((item) => {
                  if (
                    (item.href === "/timeline/" ||
                      item.href === "/favourites/") &&
                    !user
                  )
                    return null;
                  return (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  );
                })}
                {!user?.uid ? (
                  <Disclosure.Button className="">
                    <div className="py-2">
                      <button
                        type="button"
                        className="focus:outline-none ml-3 block items-center border border-transparent bg-gray-light px-6 py-2 text-sm font-medium text-black shadow hover:bg-secondary hover:text-white focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setModalOpen(true);
                        }}
                      >
                        Access
                      </button>
                    </div>
                  </Disclosure.Button>
                ) : (
                  <> </>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
}
