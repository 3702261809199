import firebase from "firebase";
import { User } from "@/interfaces/User";

// Custom imports:
import { gtmLogout } from "@/lib/analytics/gtmHelpers";
import { updateUserInRealTimeDB } from "@/lib/analytics/idempotentGtmTracking";
import { deleteFromLocalStorageAndRealTimeDB } from "@/lib/analytics/idempotentGtmTracking";

export const signOutHandler = async (user: User, uid: string) => {
  // 1. GTM Tracking:
  deleteFromLocalStorageAndRealTimeDB(uid, "login");
  deleteFromLocalStorageAndRealTimeDB(uid, "userIDSet");
  updateUserInRealTimeDB(user, "logout", "set", true);
  gtmLogout("Navigation");

  // 2. Firebase sign out:
  await firebase.auth().signOut();

  // 3. Cleaning up the IndexedDB database:
  // await deleteEmailFromIndexedDB();
  window.localStorage.removeItem("email");
};
