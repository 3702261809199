import Image from "next/image";
import Link from "next/link";

import { useAuth } from "@/lib/provider-authentication";

const navigation = {
  links: [
    { name: "Pricing Options", href: "/pricing/" },
    // { name: "Course Timeline", href: "/timeline/" },
    {
      name: "Marketing Mix Modelling",
      href: "https://www.vexpower.com/paths/marketing-mix-modeling",
    },
    { name: "Discord Community", href: "https://discord.gg/MQRnp8XbkA" },
    {
      name: "Request a Course",
      href: "https://brightpool.canny.io/course-requests",
      current: false,
    },
    {
      name: "Terms & Conditions",
      href: "/terms-and-conditions/",
    },
    { name: "Privacy Policy", href: "https://www.vexpower.com/privacy" },
  ],
};

export default function Footer() {
  const { user } = useAuth();
  return (
    <footer
      className="mt-24 bg-background-dark sm:mt-32"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="sm:grid sm:grid-cols-2">
          <div className="space-y-8 xl:col-span-1">
            <Link href="/courses/">
              <a href="/courses/">
                <Image
                  priority="true"
                  className="hidden h-8 w-auto lg:block"
                  src="https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2Fvexpower-logo.svg?alt=media&token=fb1b711a-912f-4aa3-a768-25e0ab52ea02"
                  alt="Company Logo - Vexpower"
                  height="30"
                  width="120"
                />
              </a>
            </Link>
            <p className="text-base text-gray-400">
              Helping marketers learn how to make data-driven decisions.
            </p>
            <p className="text-base text-gray-400">
              If you have any problems or questions, please{" "}
              <a className="text-white" href="mailto:mike@vexpower.com">
                contact me directly.
              </a>
            </p>
            <p className="text-base text-white">
              <span className="text-base text-gray-400">
                © {new Date().getFullYear()} Copyright{" "}
              </span>{" "}
              BRIGHTPOOL LEARNING LTD
            </p>
          </div>

          <div className="mt-6 sm:mt-0 md:grid md:grid-cols-2">
            <div className="md:col-end-3 md:mt-0">
              <ul role="list" className="space-y-2">
                {navigation.links.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-base font-medium text-gray-400 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto w-2/3">
        <div className="mx-auto text-center text-sm text-gray-400">
          <small>
            Hey{" "}
            <span className="text-indigo-200">
              {user ? user.displayName : "there"}
            </span>
            . It's another fine day in the simulation...
          </small>
          <div className="mx-auto w-full text-center text-xs text-gray-dark">
            {user ? "#" + user.uid : ""}
          </div>
        </div>
      </div>
    </footer>
  );
}
