const navigation = [
  { name: "Courses", href: "/courses/", current: false },
  // TODO - This needs fixing and has a large page size:
  // { name: "Timeline", href: "/timeline/", current: false },
  { name: "Skill Paths", href: "/paths/", current: false },
  { name: "Skills", href: "/skills/", current: false },
  { name: "Pricing", href: "/pricing/", current: false },
  { name: "Community", href: "https://discord.gg/MQRnp8XbkA", current: false },
  {
    name: "Course Feedback",
    href: "https://brightpool.canny.io/course-requests",
    current: false,
  },
];
export default navigation;
